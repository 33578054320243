@import '../../../styles/variables';
@import '../../../styles/mixin';
.dealersHeader {
  margin-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .title {
      font-size: 20px;
    }

    h1 {
      line-height: 35px;
      font-size: 24px;
      color: #425363;
      font-weight: 400;
      margin-right: 10px;
    }

    .toggleSearchForm {
      position: relative;
      justify-content: center;
      border: none;
      padding: 4px 8px;
      background: none;
      font-size: 14px;
      color: $hidden;
      display: flex;
      align-items: center;
      border-radius: 2px;

      &.isActivated {
        svg {
          transform: rotate(180deg);
        }
      }

      .text {
        padding-right: 16px;
      }

      svg {
        vertical-align: middle;
        font-size: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 8px;
      }
    }
  }

  .basicInformation,
  .dealerInformation {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .column {
    display: flex;
    align-items: center;
    margin-right: 35px;
    margin-bottom: 10px;

    .label {
      font-size: 12px;
      min-width: 60px;
      padding-right: 10px;
      color: $hidden;
      text-align: right;
      box-sizing: border-box;
    }
  }

  .dropdown {
    min-width: 145px;
  }

  .ageSelector, .createdDateSelector {
    display: flex;
    align-items: center;

    .approximately {
      font-size: 12px;
      color: $hidden;
      margin: 0 5px;
    }
  }

  .toggleAllStatuses {
    padding: 8px 10px;
    border-radius: 4px;
    border: none;
    position: relative;
    color: $deepBlue;
    appearance: none;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: rgba(67, 90, 111, 0.14) 0 1px 2px inset, rgba(67, 90, 111, 0.3) 0 0 0 1px inset;
    background: #ffffff;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(249, 250, 252));

    &:focus,
    &:hover {
      box-shadow: rgba(67, 90, 111, 0.14) 0 0 2px inset, $sky 0 0 0 1px inset, rgba($sky, 0.14) 0px 0px 0px 2px;
    }
  }

  .dealerStatuses {
    display: flex;
    flex-wrap: wrap;
    min-height: 20px;
    padding-top: 10px;
    flex-direction: column;

    .dealerStatusGroup {
      display: flex;
      flex-wrap: wrap;
      & > label {
        margin-bottom: 10px;
      }
    }
  }

  .actions {
    display: flex;

    .submit {
      cursor: pointer;
      border-radius: 4px;
      width: 70px;
      height: 30px;
      font-size: 12px;
      background: #425363;
      color: #fff;
      border: 0;
      position: relative;
      transition: box-shadow 0.15s ease-in-out;
      &:hover {
        box-shadow: 1.5px 1.5px 5px rgba(#425363, 0.7);
      }
    }
  }
}
