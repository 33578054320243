.sideBarItem {
  height: 40px;
  padding: 0 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #344657;
    .label {
      color: #00b0ff;
    }
  }

  .link {
    text-decoration: none;
    color: #fff;
  }

  .label {
    color: white;
    font-size: 12px;
  }
}

.active {
  background-color: #344657;
  .label {
    color: #00b0ff;
  }
}

.carNumber {
  color: white !important;
}
