@import '../../styles/variables';

.profileTag {
  position: relative;
  background-color: $blue;
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 2px;
  transition: box-shadow 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: 1px 1px 3px rgba($blue, 0.7);
  }
}
