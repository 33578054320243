.infoWrapper {
  position: relative;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  .info {
    width: 22px;
    height: 22px;
    cursor: pointer;
    .icon {
      width: 22px;
      height: 22px;
    }
  }
  .tooltip {
    visibility: hidden;
    opacity: 0;
    display: block;
    position: absolute;
    width: 200px;
    top: 0;
    .template {
      white-space: pre-line;
      font-size: 10px;
      line-height: 1.41;
      color: rgba(255, 255, 255, 0.89);
      .title {
        color: white;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
