.content {
  width: 100%;
  height: calc(100vh - 60px);
}
.warning {
  z-index: 1;
  color: white;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}
.dimmer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
