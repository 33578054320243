@import '../../styles/variables';

.dropdownField {
  display: flex;
  align-items: center;
  height: 30px;

  .label {
    color: #98a3ad;
    font-size: 12px;
    margin-right: 10px;
  }

  .dropdown {
    height: 30px;
    cursor: pointer;
    padding: 8px 10px;
    min-width: 70px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.5;
    color: #425363;
    border: none;
    appearance: none;
    background-color: #ffffff;
    background-image: linear-gradient(rgb(255, 255, 255), rgb(249, 250, 252));
    box-shadow: rgba(67, 90, 111, 0.3) 0 0 0 1px inset, rgba(67, 90, 111, 0.14) 0 1px 2px inset;
    transition: background-image 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus,
    &:hover,
    &.isOpen {
      box-shadow: rgba(67, 90, 111, 0.14) 0 0 2px inset, $sky 0 0 0 1px inset, rgba($sky, 0.14) 0px 0px 0px 2px;
    }

    & > .inner {
      display: flex;
      position: relative;

      .name {
        display: flex;
        line-height: 12px;
        height: 12px;
        position: relative;
        top: 2px;
      }

      & > .icon {
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 14px;
        height: 14px;
        position: absolute;

        svg {
          font-size: 14px;
          color: $hidden;
        }
      }
    }
  }
}

.clickOutWrapper {
  position: absolute;
  z-index: 9999;

  .popup {
    position: absolute;
    min-width: 240px;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
    border-radius: 5px;
    background: #ffffff;
    overflow: hidden;

    .search {
      display: flex;
      flex-direction: row;
      height: 30px;
      border-bottom: 1px solid rgb(228, 231, 235);
      background: rgb(245, 246, 247);
      box-sizing: border-box;

      input {
        font-size: 12px;
        border: none;
        background: none;
      }

      & > .icon {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: rgb(123, 139, 154);
      }
    }

    .options {
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: relative;

      .scroll {
        width: 100%;
        display: flex;
        flex-direction: column;

        .option {
          display: flex;
          height: 36px;
          transition: background 0.15s ease-in-out;
          cursor: pointer;
          padding-left: 32px;
          position: relative;

          .icon {
            position: absolute;
            font-size: 16px;
            color: $sky;
            left: 8px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 16px;
            height: 16px;
          }

          &:last-child {
            .inner {
              border-bottom: none;
            }
          }

          &:hover {
            background: rgba($sky, 0.075);
          }

          &.isSelected {
            background: rgba($sky, 0.1);

            .inner {
              color: $sky;
            }
          }

          .inner {
            width: 100%;
            height: 100%;
            font-size: 12px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgb(237, 240, 242);
          }
        }
      }
    }
  }
}
