@import '../../../../styles/variables';

.days {
  display: flex;
  background-color: #F5F5F5;
  border-bottom: 1px solid $border;
  & > div {
    width: calc(100% / 7);
    text-align: center;
    margin: 8px 0;
    font-size: 12px;
    font-weight: bold;
    color: $deepBlue
  }
}