$primary: #2a7de2;
$secondary: #ec6878;
$header: #2a7de2;
$side-bar: #425363;
$category-text: #98a3ad;
$border: #e7eaec;
$hd_navy_8: #272e40;
$hd_navy_5: #afc2db;
$hd_navy_4: #ccd8e8;
$hd_navy_3: #e9edf4;
$hidden: #c8d1d9;
$aj: #f56209;
$paper: #fafafa;

$mw: 540px;

$font: 13px;
$fontColor: #425363;
$sidebar_width: 200px;
$responsiveWidth: 600px;
$responsiveMediumWidth: 1300px;
$responsiveMd: 64em;

$sky: #00b0ff;
$blue: #2a7de2;
$lightBlue: #5587e0;
$gray: #425363;
$hidden: #98a3ad;
$black_gray: #263645;
$blackGray: #263645;
$disabled: #dadfe1;
$skyBlue: #679dff;

$silver: #98a3ad;
$orange: #ffbd5a;
$red: #ec6878;
$green: #39c889;
$pumpkin: #e67e22;
$purple: #ba7bf1;
$cloud: #c8d1d9;

$disable: #c8d1d9;
$deepBlue: #425363;
$lightGray: #e7eaec;

$colors: (
        (
                key: 'sky',
                value: $sky
        ),
        (
                key: 'gray',
                value: $silver
        ),
        (
                key: 'yellow',
                value: $orange
        ),
        (
                key: 'red',
                value: $red
        ),
        (
                key: 'green',
                value: $green
        ),
        (
                key: 'brown',
                value: $pumpkin
        ),
        (
                key: 'purple',
                value: $purple
        )
);
