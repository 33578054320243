.content {
  width: calc(100% - 250px);
  margin-left: 250px;
  height: calc(100vh - 60px);
  overflow: auto;
  padding: 40px 30px;
  box-sizing: border-box;
}

.header {
  .title {
    font-size: 20px;
    margin-bottom: 35px;
  }
}

.body {
  margin-top: 75px;
  margin-bottom: 75px;

  .contentHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .filterWrapper {
    margin-right: 20px;
  }

  .isUserState {
    max-width: 600px;
  }

  .chartWrapper {
  }

  .chartWrapper,
  .tableWrapper {
    background: #ffffff;
    padding: 20px;
    border-radius: 2px;

    .title {
      font-size: 18px;
      font-weight: normal;
      margin-bottom: 10px;
      letter-spacing: -0.5px;
    }
  }
  .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 720px) {
  .contentWrapper {
    margin-top: 50px;
  }
}

.dimmer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
