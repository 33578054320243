@import '../../../styles/variables';

.dateWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid black;
  .date {
    display: flex;
    align-items: center;
    height: 20px;
    width: 80px;

    .writeInput {
      border: none;
      padding: 5px;
      background: none;
    }

    .text {
      font-family: Arial;
      font-weight: bold;
      font-size: 14px;
      color: $font;
      text-align: left;
      margin-right: 14px;
      letter-spacing: 1.5px;
    }

    .label {
      font-family: Arial;
      font-size: 12px;
      color: $hidden;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background-color: #f4f3f3;
      border-left: 1px solid #dadfe1;
    }
  }

  .outsideLabel {
    font-size: 10px;
    color: $hidden;
    position: absolute;
    top: -14px;
    left: 0;
  }
}
