@import '../../styles/variables';

.content {
  width: calc(100% - 250px);
  min-height: calc(100vh - 45px);
  margin-left: 250px;
  padding: 40px 30px;
  box-sizing: border-box;
  overflow: hidden;
}

.loginContent {
  width: 100%;
}
