@import '../../../styles/variables';

.calendar {
  width: 265px;
  height: 340px;
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 10px rgba(0,0,0,.19), 0 3px 3px rgba(0,0,0,.13);
  .dateDisplayWrapper {
    overflow: auto;
    height: 265px;
  }
  .footer {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    .confirm {
      position: relative;
      background-color: #396eff;
      color: white;
      padding: 2px 8px;
      border-radius: 2px;
      &:hover {
        background-color: $lightBlue;
      }
    }
    .init {
      position: relative;
      background-color: $red;
      color: white;
      padding: 2px 8px;
      border-radius: 2px;
      margin-right: 4px;
      &:hover {
        background-color: rgba($red, 0.7);
      }
    }
  }
}
