.chartWrapper {
  position: relative;

  .chart {
    width: 100%;
    height: 700px;
    background: #ffffff;

    &.second {
      position: absolute;
      left: 0;
      background: none;
    }
  }
}
