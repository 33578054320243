@import '../../../styles/variables.scss';

.commentItem {
  position: relative;
  transition: background-color 0.15s ease-in-out;

  .commentModifyForm {
    display: flex;

    input {
      width: 100%;
      height: 40px;
      border: solid #c8d1d9 1px;
      font-size: 13px;
      padding: 0 12px;
      box-sizing: border-box;
      padding-right: 100px;
      border-radius: 2px;
    }
  }

  .fields {
    &::after {
      clear: both;
      display: block;
      content: '';
    }

    font-size: 13px;

    .date,
    .content,
    .author {
      line-height: 40px;
    }

    .date,
    .content {
      float: left;
    }

    .date,
    .author {
      color: #98a3ad;
      font-size: 12px;
      font-family: 'D2 Coding', serif;
      letter-spacing: 1px;
    }

    .date {
      padding-left: 12px;
    }

    .content {
      width: calc(100% - 300px);
      margin-left: 28px;
      line-height: 1.6;
      padding: 10px 0;
      white-space: pre-wrap;
    }

    .author {
      float: right;
      padding-right: 5px;
    }
  }

  .actions {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    margin: auto;
    height: 24px;
    display: none;
    border: solid #2a7de2 1px;
    border-radius: 2px;

    button {
      width: 40px;
      height: 24px;
      white-space: nowrap;
      font-size: 12px;
      box-sizing: border-box;
      appearance: none;
      font-weight: bold;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.15s ease-in-out;
    }

    .highLightButton {
      background: white;
      .highLightIcon {
        fill: #2a7de2;
      }
      &:hover {
        background-color: rgba(144, 202, 249, 0.16);
      }
    }
    .deleteButton {
      background: #2a7de2;
      color: white;
      &:hover {
        background-color: rgba(96, 139, 255, 1);
      }
    }

    .modifyButton {
      background: white;
      color: #2a7de2;
      &:hover {
        background-color: rgba(144, 202, 249, 0.16);
      }
    }

    .confirmButton {
      background: white;
      color: #2a7de2;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      &:hover {
        background-color: rgba(144, 202, 249, 0.16);
      }
    }
    .cancelButton {
      background: #2a7de2;
      color: white;
      &:hover {
        background-color: rgba(96, 139, 255, 1);
      }
    }
  }

  &:hover {
    background: white;

    .actions {
      display: flex;
    }

    .author {
      display: none;
    }
  }

  &.isModify {
    .actions {
      display: flex;
    }
  }
}
.highLight {
  background-color: rgba(69, 109, 246, 0.2) !important;
}
