.table {
  border-collapse: collapse;

  thead {
    tr {
      &:first-child, &:last-child {
        th {
          border-bottom: solid #444 1px;
        }
      }
    }
  }

  th {
    background: #425363;
    color: #fff;
    font-size: 12px;
    padding: 10px;
    border: solid #444 1px;
  }

  tbody {
    td {
      background: #fff;
      color: #425363;
      font-size: 12px;
      padding: 10px;
      border: solid #ddd 1px;
      text-align: right;

      &.date {
        text-align: center;
      }

      &.isNotNumeric {
        text-align: center;
        width: 10%;
      }

      &.tenDaysAgoDivisionLine {
        border-bottom: solid #555 1px;
      }
    }

    tr:nth-child(odd) {
      td {
        &.date {
          background: #f4f4f4 !important;
        }
      }
    }
  }
}
