.dateFilter {
  position: relative;
  display: flex;
  align-items: center;
  .dateWrapper {
    position: relative;
    label {
      position: absolute;
      top: -14px;
      left: 0;
      font-size: 10px;
      color: rgba(0, 0, 0, 0.54);
    }
    .date {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #425363;
      height: 30px;

      .writeInput {
        border: none;
        padding: 5px;
        background: none;
      }

      .dateText {
        font-size: 14px;
        letter-spacing: 1.5px;
        font-weight: bold;
        color: #425363;
        margin-right: 14px;
      }
    }
  }
  .datePickerWrapper {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 1;
  }
}
