@import '../../../../styles/variables';

.month {
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px 12px 0 12px;
}
.daysWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  .day {
    flex-basis: calc(100% / 7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #425363;
    height: 35px;
    cursor: pointer;
    &.today {
      box-sizing: border-box;
      border: 2px solid $blue;
      border-radius: 50%;
    }
    &.hidden {
      display: none;
    }
    &.startDate {
      border-radius: 50% 0 0 50%;
      background-color: $blue;
      color: white;
    }
    &.endDate {
      border-radius: 0 50% 50% 0;
      background-color: $blue;
      color: white;
    }
    &.between {
      border-radius: 0;
      background-color: $blue;
      color: white;
    }
    &.sameDate {
      border-radius: 50%;
      background-color: $blue;
      color: white;
    }
  }
}
