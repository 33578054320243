@import '../../styles/variables.scss';

.comment {
  background: #fafafa;
  border-top: solid #dadfe1 1px;
  padding: 9px 20px 20px 20px;

  .header {
    color: $hidden;
    font-size: 13px;
    display: flex;
    background: url(../../images/comment-icon.png) no-repeat left 12px center;
    background-size: 20px;
    padding-left: 35px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 12px;
    align-items: center;
  }

  .commentForm {
    display: flex;
    margin-top: 12px;

    input,
    button {
      box-sizing: border-box;
    }

    input {
      width: calc(100% - 56px);
      height: 30px;
      border: solid #c8d1d9 1px;
      border-right: 0;
      font-size: 13px;
      padding: 0 12px;
      border-radius: 2px;
    }

    button {
      color: #fff;
      width: 56px;
      height: 30px;
      border: none;
      font-size: 13px;
      appearance: none;
      background: #425363;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  .showComments {
    cursor: pointer;
    font-size: 12px;
    margin: 14px 0;
    color: $sky;
    text-decoration: underline;
  }
}
