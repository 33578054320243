@import '../../styles/variables.scss';

th {
  font-size: 12px;
  border: 1px solid #c8d1d9;
  background-color: #fafafa;
  color: $fontColor;
  font-weight: normal;
  border-bottom: 2px solid #c8d1d9;
  .buttonWrapper {
    padding: 10px;
    position: relative;
  }
  .headerContent {
    position: relative;
    display: inline-block;
    .arrowWrapper {
      position: absolute;
      transition: transform 0.15s ease-in-out;
      right: 0;
      top: -2px;
      transform: translateX(100%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .reverse {
      transform: translateX(100%) rotate(180deg);
    }
  }
}

.sortAbleHeader {
  cursor: pointer;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #00b0ff;
    background-color: rgba(#00b0ff, 0.1);
  }
}
.isActive {
  background-color: rgba(#00b0ff, 0.2) !important;
}
td {
  border: 1px solid #c8d1d9;
  font-size: 12px;
}

.tableWrapper {
  position: relative;
  margin-top: 75px;
  margin-bottom: 50px;
  max-width: 1000px;
  .xlsxDownLoadButtonWrapper {
    position: absolute;
    top: -35px;
    right: 0;
  }
  .table {
    width: 100%;
  }
}

.download {
  position: absolute;
  top: -35px;
  right: 0;
  font-size: 12px;
  box-shadow: inset 0 0 0 1px #1d6f42;
  color: #1d6f42;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 2px;
  transition: color 0.3s, background 0.3s;

  &:hover {
    background: #1d6f42;
    color: #fff;
  }

  svg {
    font-size: 14px;
    margin-right: 2.5px;
    position: relative;
  }
}
