@import '../../styles/variables';

.inputField {
  display: flex;
  align-items: center;

  .label {
    color: #98a3ad;
    font-size: 12px;
    margin-right: 10px;
  }

  .input {
    -webkit-appearance: none;
    padding: 8px 10px;
    border-radius: 3px;
    color: $deepBlue;
    border: none;
    height: 30px;
    font-size: 12px;
    line-height: 1.5;
    box-sizing: border-box;
    transition: box-shadow 0.15s ease-in-out;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset, rgba(67, 90, 111, 0.14) 0px 1px 2px inset;
    background: #ffffff;

    &::placeholder {
      color: $disable;
    }

    &:hover,
    &:focus {
      box-shadow: rgba(67, 90, 111, 0.14) 0px 0px 2px inset, $sky 0px 0px 0px 1px inset, rgba($sky, 0.14) 0px 0px 0px 2px;
    }
  }
}
