.header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: black;
  box-shadow: 0 2px 4px 0 rgba(39, 46, 64, 0.03);
  padding: 0 20px;
  .logo {
    display: inline-block;
    width: 180px;
    height: 60px;
    background: url('../../images/logoHeader.png') center no-repeat;
    background-size: contain;
  }

  .right {
    display: flex;
    align-items: center;
    .button {
      padding: 10px;
      cursor: pointer;
      position: relative;
      color: white;
      transition: background 0.15s ease-in-out;
      border: 2px;
      &:hover {
        background-color: rgba(#fff, 0.2);
      }
    }
  }
}
