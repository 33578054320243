@import '../../styles/variables';

.header {
  .title {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .datePickerWrapper {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;

    .buttons {
      display: flex;
      align-items: center;
      margin-left: 24px;
      .quickDateButton {
        position: relative;
        border: 1px solid $hidden;
        padding: 8px;
        border-radius: 16px;
        cursor: pointer;
        transition: box-shadow 0.15s ease-in-out;
        &:hover {
          box-shadow: 1.5px 1.5px 5px rgba(#425363, 0.7);
        }
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}
