@import '../../styles/variables';

.sideBar {
  width: 250px;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  -webkit-overflow-scrolling: touch;
  background-color: $side-bar;
  transition: transform .3s;

  &.isVisible {
    transform: translateX(0);
  }

  .date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 67px;

    .icon {
      width: 20px;
    }

    .text {
      color: white;
      font-size: 15px;
    }
  }
  .copyright {
    position: absolute;
    bottom: 100px;
    transform: translateX(-5px);
    left: 0;
    right: 0;
    text-align: center;
    color: #fff;
    opacity: .7;
    font-size: 12px;
  }
}

@media screen and (max-width: 720px) {
  .sideBar {
    transform: translateX(-100%);
    height: calc(100% - 50px);
    position: fixed;
    top: 50px;
    left: 0;
  }
}
