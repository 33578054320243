.typography {
  &.label {
    font-size: 12px;
    color: #98a3ad;
  }

  &.text {
    font-size: 12px;
    color: #272e40;
  }
}
