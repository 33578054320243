.button {
  width: 135px;
  position: relative;
  font-size: 12px;
  box-shadow: inset 0 0 0 1px #1d6f42;
  color: #1d6f42;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  border-radius: 2px;
  transition: color 0.3s, background 0.3s;

  &:hover {
    background: #1d6f42;
    color: #fff;
  }


  svg {
    font-size: 14px;
    margin-right: 2.5px;
    position: relative;
  }
}

.isLoading {
  &:hover {
    background-color: transparent;
  }
}
