@import '../../styles/variables';

.sideBarList {
  background: url(../../images/status.png) no-repeat;
  background-position: bottom 20px center;
  background-size: 110%;
  padding-bottom: 456px;
  position: relative;

  .categoryWrapper {
    margin-bottom: 30px;

    .category {
      padding-left: 30px;
      height: 30px;
      display: flex;
      align-items: center;

      .icon {
        width: 10px;
        margin-right: 10px;
      }

      .text {
        font-size: 10px;
        color: $category-text;
      }
    }
  }
}
