@import '../../styles/variables';

.datePicker {
  position: relative;
  align-items: center;
  width: 240px;
  .bar {
    margin: 0 4px;
    height: 1px;
    width: 14px;
    background-color: $disabled;
    opacity: 0;
  }
  .dateInputWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .calendarWrapper {
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 9999;
  }
}
