@import '../../../../styles/variables';

.dealersListItem {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  margin-bottom: 30px;
  background: #ffffff;
  position: relative;

  .variant {
    position: absolute;
    color: #fff;
    padding: 2px 4px;
    font-size: 11px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transform: translateY(-100%);

    &.gray {
      background: $silver;
    }

    &.red {
      background: $red;
    }

    &.blue {
      background: $blue;
    }
  }

  .header {
    display: flex;
    border-bottom: solid #ddd 1px;

    .left,
    .right {
      display: flex;
      width: 50%;
      box-sizing: border-box;
    }

    .left {
      display: flex;
      align-items: center;
      padding: 20px;

      .profile {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-right: 15px;
        background: url(../../../../images/dealer-no-profile.png);
        background-size: 100px !important;
        border: 1px solid #efefef;
      }

      .tagWrapper {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 65px;
      }

      .information {
        .username {
          font-size: 12px;
          color: $hidden;
        }
        .fullName {
          font-weight: bold;
          font-size: 22px;
          margin-bottom: 12px;
          line-height: 1.6;
        }

        .office {
          font-size: 14px;
          line-height: 1.61;
        }

        .phoneNumberWrapper {
          display: flex;
          align-items: center;
          .phoneNumber {
            font-size: 14px;
            line-height: 1.61;
            color: #98a3ad;
          }
        }
      }
    }

    .right {
      flex-wrap: wrap;
      border-left: solid #ddd 1px;

      & > div {
        width: 50%;
        box-sizing: border-box;
      }

      .successRate,
      .tradeCount {
        display: flex;
        padding: 15px;
        border-right: solid #ddd 1px;

        .label {
          color: $hidden;
          font-size: 14px;
        }
      }

      .successRate,
      .starRate {
        border-bottom: solid #ddd 1px;
      }

      .successRate {
        flex-direction: column;
        justify-content: space-between;

        .label {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .successRateText {
            display: flex;
            align-items: center;
          }

          .percent {
            color: $blue;
            font-weight: bold;
          }
        }

        .progress {
          width: 100%;
          height: 5px;
          background: #efefef;
          display: flex;

          .inner {
            height: 100%;
            background: $blue;
            display: flex;
          }
        }
      }

      .starRate {
        display: flex;
        justify-content: center;
        align-items: center;

        .inner {
          display: flex;
          color: $blue;

          .star {
            background: url(../../../../images/star-icon.png) no-repeat center;
            display: flex;
            width: 20px;
            height: 20px;
            background-size: 20px;
            top: -1px;
            position: relative;
          }

          .starWrap {
            display: flex;
            margin: 0 5px;
            flex-direction: column;

            .starLabel {
              display: flex;
              color: $hidden;
              font-size: 12px;
              margin-bottom: 5px;
              padding-left: 4px;
            }

            .starValue {
              display: flex;
              font-size: 14px;
              align-items: center;
            }

            &.isIncludeNoInput {
              .starValue {
                color: $hidden;
              }

              .star {
                background: url(../../../../images/gray-star.png) no-repeat center;
                background-size: 18px;
              }
            }
          }
        }
      }

      .tradeCount {
        flex-direction: column;
        justify-content: space-between;

        .value {
          font-size: 12px;

          .hidden {
            color: $hidden;
            margin-left: 4px;
          }
        }
      }

      .warning {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 15px;

        .warningIcons {
          display: flex;

          .warningIcon {
            margin-right: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            box-sizing: border-box;
            border: solid $red 1px;

            &.isActivated {
              background: $red;
            }
          }
        }

        .mistakeCount {
          font-size: 12px;
          color: #000;
        }
      }
    }
  }
}
