@import '../../../styles/variables';

.fieldSet {
  display: flex;
  font-size: 12px;
  &:not(&:last-child) {
    margin-bottom: 24px;
  }
  .label {
    min-width: 70px;
    color: $hidden;
    margin-right: 24px;
    line-height: 24px;
  }
}
