@import '../../styles/variables.scss';

.dimmer {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;

  .popup {
    background: #fff;
    width: 300px;
    box-shadow: 0 5px 42px 1px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    margin: auto;

    .children {
      padding: 20px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;

      button {
        border: 0;
        width: 80px;
        height: 40px;
        border-radius: 2px;
        position: relative;
        font-size: 14px;
        margin-left: 10px;
      }

      .apply {
        background: $blue;
        color: #fff;
      }

      .cancel {
        color: $blue;
      }
    }
  }
}
