@import './styles/mixin';

@font-face {
  font-family: 'SDPRNDGothicNeoaUni-fSb';
  font-weight: normal;
  font-style: normal;
  src: url('./font/SDPRNDGothicNeoaUni-fSb.woff');
}

@font-face {
  font-family: 'SDPRNDGothicNeoaUni-dRg';
  font-weight: normal;
  font-style: normal;
  src: url('./font/SDPRNDGothicNeoaUni-dRg.woff');
}

@font-face {
  font-family: 'SDPRNDGothicNeoaUni-cLt';
  font-weight: normal;
  font-style: normal;
  src: url('./font/SDPRNDGothicNeoaUni-cLt.woff');
}

@font-face {
  font-family: 'D2 Coding';
  font-weight: normal;
  font-style: normal;
  src: url('./font/D2Coding-Ver1.3.2-20180524.ttf');
}

body {
  font-size: 14px;
  margin: 0;
  font-family: 'SDPRNDGothicNeoaUni-dRg', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(248, 249, 250);
  color: #425363;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
}

th,
td {
  box-sizing: border-box;

  text-align: center;
  font-size: 10px;
  line-height: 1.67;
}

td {
  padding: 10px;
}

th {
  @include font('fsb');
  color: white;
}

a {
  text-decoration: none;
  outline: none;
}

a:hover,
a:active {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

input:focus {
  outline: none;
}
