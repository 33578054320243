@import '../../../styles/variables';
.dealersList {
  max-width: 1000px;
  .header {
    margin-bottom: 10px;

    .count {
      color: $hidden;
      font-size: 12px;
    }
  }

  .actions {
    margin-bottom: 30px;
  }

  .noDealers{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #425363;
    line-height: 1.67;
    text-align: center;
    margin: 100px;
  }
}
