.dealerActivities {
  padding: 0 1rem;

  h3 {
    margin-top: 2rem;

    svg {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.dealerActivity {
  display: flex;

  box-sizing: border-box;
  width: 100%;
  padding: 2rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
  }

  .barChartWrapper {
    flex: 1 0 auto;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }

    .title {
      font-size: 15px;
      color: #425363;
      text-align: center;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 12px;
      color: #98a3ad;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
