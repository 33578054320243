.tooltip {
  position: relative;
  padding: 10px 15px;
  background: #263645;
  border-radius: 2px;
  z-index: 1;
  font-size: 10px;
  color: white;
}

.left {
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: -6px;
    border-width: 3px;
    border-style: solid;
    border-color: transparent #263645 transparent transparent;
  }
}
.right {
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    right: -6px;
    border-width: 3px;
    border-style: solid;
    border-color: transparent transparent transparent #263645;
  }
}
